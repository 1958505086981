var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('inventoryList.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('inventoryList.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-space-x-3"},[_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),(!_vm.$whois.shop())?_c('a-select',{staticStyle:{"width":"200px"},attrs:{"show-search":"","placeholder":_vm.$t('inventoryList.shopName'),"default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null,"loading":_vm.shop_loading},on:{"search":_vm.handleSearch,"change":_vm.onCustomerChange},model:{value:(_vm.shop_id),callback:function ($$v) {_vm.shop_id=$$v},expression:"shop_id"}},_vm._l((_vm.shopList),function(s){return _c('a-select-option',{key:s.shop_id},[_vm._v(" "+_vm._s(s.shop_name)+" ")])}),1):_vm._e()],1),_c('a-tooltip',{attrs:{"placement":"topLeft","title":_vm.$t('inventoryList.search')}},[_c('a-input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('inventoryList.search')),expression:"$t('inventoryList.search')"}],staticClass:"inline-block",staticStyle:{"width":"250px"},attrs:{"placeholder":_vm.$t('inventoryList.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.product_name_kh))]),_c('td',[_vm._v(_vm._s(record.product_name_en))]),_c('td',[_vm._v(_vm._s(record.uom_name_en))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.begining_balance)+" ")]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.stock_in))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.stock_out))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.ending_balance)+" ")])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }