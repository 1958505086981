<template>
    <div>
        <ts-page-title
            :title="$t('inventoryList.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('inventoryList.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
								<div class="tw-flex tw-justify-between">
									<div class="tw-flex tw-space-x-3">
										<a-range-picker
												v-model="dateRange"
												:allowClear="false"
												format="DD-MM-YYYY"
												valueFormat="DD-MM-YYYY"
												style="width: 200px"
												@change="fetchData()"
										/>
										<a-select
											v-if="!$whois.shop()"
											show-search
											v-model="shop_id"
											:placeholder="$t('inventoryList.shopName')"
											style="width: 200px"
											:default-active-first-option="false"
											:show-arrow="false"
											:filter-option="false"
											:not-found-content="null"
											:loading="shop_loading"
											@search="handleSearch"
											@change="onCustomerChange"
										>
											<a-select-option v-for="s in shopList" :key="s.shop_id">
												{{ s.shop_name }}
											</a-select-option>
										</a-select>
									</div>
									<a-tooltip placement="topLeft" :title="$t('inventoryList.search')">
										<a-input
											v-model="search"
											v-tooltip="$t('inventoryList.search')"
											:placeholder="$t('inventoryList.search')"
											style="width: 250px"
											class="inline-block"
										/>
									</a-tooltip>
								</div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.shop_name }}</td>
                        <td>{{ record.product_name_kh }}</td>
                        <td>{{ record.product_name_en }}</td>
                        <td>{{ record.uom_name_en }}</td>
                        <td class="tw-text-center">
                            {{ record.begining_balance }}
                        </td>
                        <td class="tw-text-center">{{ record.stock_in }}</td>
                        <td class="tw-text-center">{{ record.stock_out }}</td>
                        <td class="tw-text-center">
                            {{ record.ending_balance }}
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { debounce } from "lodash";
import { mapActions, mapState } from "vuex";
export default {
    name: "stockIn-Index",
    data() {
        return {
            errors: new Errors(),
            loading: false,
						shop_loading: false,
						shop_id: undefined,
						shopList: []
        };
    },
    computed: {
        ...mapState("inventory/inventoryList", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.inventory.inventoryList.search;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/inventoryList/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "inventory/inventoryList/RESET_CURRENT_PAGE"
                );
            }
        },
        dateRange: {
            get() {
                return this.$store.state.inventory.inventoryList.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/inventoryList/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                { name: this.$t("inventoryList.shopName") },
                { name: this.$t("inventoryList.productNameKh") },
                { name: this.$t("inventoryList.productNameEN") },
                { name: this.$t("inventoryList.uom") },
                {
                    name: this.$t("inventoryList.beginBalance"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("inventoryList.stockIn"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("inventoryList.stockOut"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("inventoryList.endingBalance"),
                    style: "text-align:center"
                }
            ];
        }
    },

    methods: {
				...mapActions('inventory/inventoryList', ['getFormViewData']),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("inventory/inventoryList/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
				handleSearch: debounce( function(value){
					this.shop_loading = true
					this.getFormViewData({
						params: { 
							search: value
						}
					})
					.then((response) => {
						this.shopList = response.data.shop
					})
					.catch((error) => {
						this.$notify({ type: "error", text: error.message });
					})
					.finally(() => {
						this.shop_loading = false;
					});
				}, 1000),
				onCustomerChange(){
					this.fetchData({ shop_id: this.shop_id })
				}
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("inventory/inventoryList/RESET_STATE");
        next();
    }
};
</script>
